import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

function Photo({ file }) {
  const imageUrl = file.url;
  return (
    <div className="p-4 w-full md:w-1/3 sm:mb-0 mb-6">
      <div className="rounded-lg h-64 overflow-hidden">
        <img alt="content" className="object-cover object-center h-full w-full" src={`${imageUrl}?w=400`} />
      </div>
    </div>
  );
}


class FieldTripTemplate extends React.Component {
  render() {
    const item = this.props.data.contentfulTripsEvents;
    const { permalink } = this.props.pageContext;
    const { title, photo } = item;

    // const imageSrc = image && image.file ? `${image.file.url}` : null;
    // const bodyHtml = body.childMarkdownRemark ? body.childMarkdownRemark.html : null;
    const sectionStyle = {};


    return (
      <Layout>
        <SEO title={title} type="article" permalink={`${process.env.GATSBY_APP_URL}${permalink}`} />
        <section className="text-gray-700 body-font" style={sectionStyle}>
          <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-col text-left w-full lg:w-2/3 mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{title}</h1>
            </div>

            <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
              {photo.map(item => <Photo key={item.id} file={item.file} title={item.title} />)}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default FieldTripTemplate;

export const pageQuery = graphql`
  query TripsEventsBySlug($permalink: String!) {
    contentfulTripsEvents(permalink: { eq: $permalink }) {
      photo {
        id
        file {
          url
          fileName
          details {
            size
          }
        }
        title
      }
      id
      permalink
      title
    }
  }
`;